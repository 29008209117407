








































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import {
  UserDetail,
  AddComposeUser,
  EditComposeUser,
  getJueses,
} from "@/request/manage";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends Vue {
  private type: any = "新增";
  private data: any = {}; // 编辑时的数据
  private user: any = {
    role_list: [],
  };
  private zhiweis: any = [
    "本科生",
    "研究生",
    "博士生",
    "主治医生",
    "副主任医生",
    "主任医生",
    "讲师",
    "副教授",
    "教授",
  ];
  private jueses: any = [
    "普通用户",
    "数据校验员",
    "数据标注员",
    "数据审核员",
    "系统管理员",
  ];
  /**
   * @description 获取用户详情
   */
  private getUserDetail() {
    const params: any = {
      params: {
        user_id: this.data._id,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    UserDetail(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.user = data;
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 保存
   */
  private save() {
    if (this.user["name"] === "admin") {
      this.$message.warning("对不起，不能编辑超级管理员账号");
      return;
    }
    // if (this.user["用户名"].length > 18) {
    //   this.$message.warning("账户名太长，请输入18个字符以内的账户名");
    //   return;
    // }
    if (!this.user["account"]) {
      this.$message.warning("请填写账户名");
      return;
    }
    if (!this.user["name"]) {
      this.$message.warning("请填写姓名");
      return;
    }
    if (!this.user["gender"]) {
      this.$message.warning("请选择性别");
      return;
    }
    if (!this.user["phone"]) {
      this.$message.warning("请填写手机号");
      return;
    }
    if (this.user["phone"].length !== 11) {
      this.$message.warning("手机号长度不正确");
      return;
    }
    // if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.user["手机号"])) {
    //   this.$message.warning("手机号格式不正确");
    //   return;
    // }
    if (this.user["role_list"].length < 1) {
      this.$message.warning("角色必须选择");
      return;
    }
    if (this.user["is_active"] !== true && this.user["is_active"] !== false) {
      this.$message.warning("状态必须选择");
      return;
    }
    const params = JSON.parse(JSON.stringify(this.user));
    params.status = this.user.is_active === "正常" ? true : false;
    if (this.user._id) {
      params.user_id = this.user._id;
      EditComposeUser(this, params).then((data: any) => {
        this.$message.success(this.type + "成功");
        this.getUser();
        this.$router.back();
      });
    } else {
      AddComposeUser(this, params).then((data: any) => {
        this.$message.success(this.type + "成功");
        this.$router.back();
      });
    }
  }
  private getJueseOptions() {
    getJueses(this).then((res: any) => {
      this.jueses = res;
    });
  }
  private getUser() {
    const params: any = {
      params: {
        user_id: this.$store.state.user.user_id,
      },
    };
    UserDetail(this, params).then((res: any) => {
      this.$store.commit("updateQuanxianData", res);
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.getJueseOptions();
    const data = this.$store.state.zhanghu;
    const id = this.$route.query.id;
    if (id) {
      this.type = "编辑";
      this.data._id = id;
      this.getUserDetail();
    } else {
      this.type = "新增";
    }
  }
}
